export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const attrs_table = {
    "disable-sort": true,
    "disable-pagination": true,
    "hide-default-footer": true,
};
