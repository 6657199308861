<template>
<div style="position: fixed; top: 0; left: 0; width: 0; height: 0; z-index: 999;">
    <transition v-for="popup in popups" :key="popup._id">
        <component :is="skinComponent" :popup="popup"></component>
    </transition>
</div>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: "basic" }
    },
    data(){
        return {
            popups: []
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { popups } = await api.v1.popups.gets();
            this.popups = popups;
		},
	},
    computed: {
        skinComponent(){
            return () => import(`./skin/${this.$props.skin}/view.vue`);
        }
    }
}
</script>
