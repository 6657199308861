var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? 'text-' + _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 9"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0,8.08l.91,.92L8,1.84l7.09,7.16,.92-.92L8,0,0,8.08Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? 'text-' + _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 9 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M8.08341 16L9 15.0924L1.8419 8.00432L9 0.916262L8.08342 8.73544e-07L6.99004e-07 8.00432L8.08341 16Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }