module.exports = {
    // common
    회사소개: "회사소개",
    서비스: "서비스",
    고객사: "고객사",
    연혁: "연혁",
    "오시는 길": "오시는 길",
    대표이사: "대표이사",
    사업자등록번호: "사업자등록번호",
    문의번호: "문의번호",
    팩스: "팩스",
    이메일: "이메일",
    주소: "주소",

    // information
    siteName: "링크넷코리아",
    ceo: "홍대진",
    address: "07549 서울시 강서구 양천로 532, 가양역더리브아너비즈타워 703, 704호",

    // main
    main: {
        title: "링크넷코리아를 방문해 주신 모든 분들께",
        titlePrimary: "진심으로 감사드립니다.",

        text1: "링크넷코리아는 보다 안전하고 풍요로운 인터넷 네트워크 환경을 제공하기 위해, 최고의 기술진과컨설턴트들이 모여 만든 젊은 회사입니다.",
        text2: "정부기관, 관공서, 국방 등 주요정보통신기반시설과 은행, 증권사, 캐피탈 등 금융권과 민간기업의 수많은 프로젝트 수행을 통해\r\n철도 SI 개발, 인프라 구축 및 안전한 망분리와 망연계솔루션, 시스템 접근제어 솔루션, 통합로그인솔루션을 판매, 구축하고 통합유지보수 하고 있습니다.",
    },

    // client
    공공기관: "공공기관",
    지자체: "지자체",
    금융: "금융",
    일반기업: "일반기업",
    기타: "기타",

    // service
    service: {
        title: "Link-net Korea는 고객의 가치를 최우선으로 고려하여",
        titlePrimary: "최고의 기술력과 노하우로 고객 감동을 실현합니다.",
        text: "Link-Net Korea는 공공 및 기업의 비즈니스 환경 변화의 즉각적으로\r\n대응하기 위하여 경험이 풍부한 전문인력을 중심으로 고객의 입장에서\r\n요구사항을 도출하고 수많은 SI 프로젝트에서 쌓은 지식을 접목하여\r\n맞춤형 서비스를 제공하며 고객 만족도를 향상시킵니다.",
    },
    serviceMaintenance: {
        title: "통합 유지보수",
        text: "시스템 통합(System Integration), 아웃소싱 서비스(Outsourcing Sevices), 솔루션 및 컨설팅(Solution & Consulting),전문분야 특화 서비스(Professional Services)등의 분야에서 최적의 Total IT Service를 제공해 드리겠습니다.",
        list1: "어플리케이션/인프라/네트워크 아웃소싱",
        list2: "통합유지보수 및 위탁운영관리 서비스",
        image: "/images/sub/service/service-img2.jpg",
        imageMobile: "/images/sub/service/service-img2-mo.jpg",
    },
    servicePurpose: {
        title: "유지보수 목적",
        list1: "통신망의 주기적 검증 및 분석을 통한 통신망 최적화",
        list2: "예방 정비로 장애요소 사전 제거",
        list3: "신규이전 및 장애처리의 신속한 대처로 고객만족 실현",
    },
    serviceSI: {
        title: "SI사업",
        text: "Link-net Korea는 경험이 풍부한 전문인력을 중심으로 고객의 입장에서 요구사항을 도출하고\r\n많은 프로젝트에서 쌓은 지식을 접목한 서비스를 제공하여 고객 만족도를 향상 시킵니다.",
        public: "공공SI서비스",
        publicText: "대국민 서비스를 제공하는 행정기관 및 철도공사, 지하철공사와 같은 기관을 대상으로\r\n인프라 및 보안 시스템 구축을 통하여 고객만족을 달성합니다.",
        finance: "금융SI서비스",
        financeText: "금융서비스는 코로나 팬데믹 이후 급격하게 비대면, 디지털 금융 도입과 신기술을 접목한\r\n금융 환경으로 빠르게 전환되고 있습니다. 금융기관 및 관계기관에서 요구하는 인프라 및\r\n보안 시스템 구축을 통하여 안전한 금융 비즈니스의 완성을 도모합니다.",
    },
};
