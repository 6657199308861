var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteName")) + " ")])])], 1), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__gnb"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1), _c('v-btn', {
    staticClass: "ml-auto ml-xl-0",
    attrs: {
      "max-width": "60",
      "small": "",
      "to": "/en",
      "rounded": "",
      "outlined": ""
    }
  }, [_vm._v("ENG")])], 1)])], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hamburger-btn"
  }, [_c('span')]);

}]

export { render, staticRenderFns }