<template>
    <div class="default-banner main-auctions-banner">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="program in programs" :key="program._id">
                    <a href="#" class="main-auctions-banner__inner">
                        <div class="main-auctions-banner__img-wrap">
                            <div class="main-auctions-banner__img" :style="`background-image:url(${program?.thumb?.url});`"></div>
                        </div>
                        <div class="main-auctions-banner__con">
                            <h3 class="main-auctions-banner__tit">{{ program?.subject }}</h3>
                            <div class="main-auctions-banner__info">
                                <ul>
                                    <li>
                                        <strong>장소</strong><span>{{ program?.location }}</span>
                                    </li>
                                    <li>
                                        <strong>일시</strong><span>{{ program?.at?.toDate?.() }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <v-divider class="grey-38"></v-divider>
                    </a>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-arrow-left mr-6"></i><span>PREV</span></div>
                <div class="swiper-pagination"></div>
                <div class="swiper-next"><span>NEXT</span><i class="icon icon-arrow-right ml-6"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import api from "@/api";
export default {
    props: {},
    data() {
        return {
            swiper: undefined,
            programs: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { programs } = await api.v1.auction.programs.gets({
                params: { mode: "upcoming" },
            });

            this.programs = programs;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-auctions-banner .swiper-container", {
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: ".main-auctions-banner .swiper-pagination",
                        clickable: true,
                    },
                    navigation: {
                        prevEl: ".main-auctions-banner .swiper-prev",
                        nextEl: ".main-auctions-banner .swiper-next",
                    },
                    breakpoints: {
                        576: {
                            spaceBetween: 30,
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        1200: {
                            freeMode: true,
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// main-auctions-banner
.main-auctions-banner__inner {
    display: block;
}
.main-auctions-banner__con {
    padding-top: 16px;
    padding-bottom: 26px;
}
.main-auctions-banner__img-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}
.main-auctions-banner__img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-auctions-banner__tit {
    font-size: 1.8rem;
    color: var(--v-primary-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-notoserif);
}
.main-auctions-banner__info {
    margin-top: 10px;
}
.main-auctions-banner__info > ul > li {
    display: flex;
    font-size: 1.4rem;
    color: #5a5a5a;
    margin-top: 6px;
}
.main-auctions-banner__info > ul > li:first-child {
    margin-top: 0;
}
.main-auctions-banner__info > ul > li > strong {
    display: inline-block;
    min-width: 30px;
    font-weight: 400;
    color: var(--v-primary-base);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .main-auctions-banner__con {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .main-auctions-banner__tit {
        font-size: 2.4rem;
    }
    .main-auctions-banner__info {
        margin-top: 14px;
    }
    .main-auctions-banner__info > ul > li {
        font-size: 1.6rem;
    }
    .main-auctions-banner__info > ul > li > strong {
        min-width: 40px;
    }
}
@media (min-width: 1200px) {
}
</style>
