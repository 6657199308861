module.exports = {
    // common
    회사소개: "Introduction",
    서비스: "Service",
    고객사: "Client",
    연혁: "History",
    "오시는 길": "Location",
    대표이사: "CEO",
    사업자등록번호: "Business Registration Number",
    문의번호: "Tel",
    팩스: "Fax",
    이메일: "Email",
    주소: "Address",

    // information
    siteName: "Link-Net Korea",
    ceo: "Hong Daejin",
    address: "#703, 704, 532, Yangcheon-ro, Gangseo-gu, Seoul, Republic of Korea",

    // main
    main: {
        title: "We sincerely thank everyone",
        titlePrimary: "who visited Link-Net Korea.",

        text1: "Link-Net Korea is a young company created by top engineers and consultants to provide a safer and more prosperous Internet network environment.",
        text2: "We carry out a number of projects for major information and communication infrastructure such as government agencies, public offices, and national defense, as well as financial institutions and private companies such as banks, securities firms, and capital.",
    },

    // client
    공공기관: "Public institutions",
    지자체: "Local governments",
    금융: "Finance",
    일반기업: "General company",
    기타: "Other",

    // service
    service: {
        title: "Link-Net Korea puts customer value first\r\nand delivers customer satisfaction",
        titlePrimary: "with the best technology and know-how.",
        text: "In order to immediately respond to changes in the public\r\nand corporate management environment, Link-Net Korea\r\nworks with experienced experts to derive requirements\r\nfrom the customer's perspective and provides customized\r\nservices by combining knowledge accumulated from\r\nnumerous SI projects. Improves satisfaction.",
    },
    serviceMaintenance: {
        title: "Integrated maintenance",
        text: "We will provide optimal Total IT Service in the areas of system integration, outsourcing services, solutions and consulting, and specialized services.",
        list1: "Application/Infrastructure/Network Outsourcing",
        list2: "Integrated maintenance and consignment operation management service",
        image: "/images/sub/service/en/service-img2.jpg",
        imageMobile: "/images/sub/service/en/service-img2-mo.jpg",
    },
    servicePurpose: {
        title: "Maintenance purpose",
        list1: "Optimization of communication network through periodic verification and analysis of communication network",
        list2: "Eliminate obstacles in advance through preventive maintenance",
        list3: "Realizing customer satisfaction through rapid response to new transfers and troubleshooting",
    },
    serviceSI: {
        title: "SI Business",
        text: "Link-net Korea improves customer satisfaction by deriving requirements from the customer's perspective and providing services that combine knowledge accumulated from many projects with experienced professionals.",
        public: "Public SI Service",
        publicText: "We achieve customer satisfaction through development, infrastructure, and security system construction\r\nfor administrative agencies, railroad corporations, and subway corporations that provide services to the public.",
        finance: "Financial SI service",
        financeText: "Financial services are rapidly transitioning to a financial environment that incorporates non-face-to-face,\r\ndigital finance and new technologies following the Corona pandemic. We seek to complete safe financial business\r\nby establishing the infrastructure and security systems required by financial institutions and related organizations.",
    },
};
