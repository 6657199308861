var render = function render(){
  var _vm$solution, _vm$server, _vm$network, _vm$software;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('section', {
    staticClass: "main-visual"
  }, [_c('v-container', [_c('div', {
    staticClass: "main-visual__txt"
  }, [_c('div', {
    staticClass: "main-visual__txt-top",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-duration": "500"
    }
  }, [_c('div', {
    staticClass: "main-visual__txt-area",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-duration": "500"
    }
  }, [_c('div', {
    staticClass: "page-text font-size-lg-20 font-play mb-12"
  }, [_vm._v("Link")]), _c('h3', [_vm._v("사람과 사람을 잇고")])]), _c('div', {
    staticClass: "main-visual__txt-area",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-duration": "500"
    }
  }, [_c('div', {
    staticClass: "page-text font-size-lg-20 font-play mb-12"
  }, [_vm._v("Network")]), _c('h3', [_vm._v("사람과 세상을 연결하는")])]), _c('div', {
    staticClass: "main-visual__txt-area",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-duration": "500"
    }
  }, [_c('div', {
    staticClass: "page-text font-size-lg-20 font-play primary--text"
  }, [_vm._v("Link & Network")])])]), _c('h2', {
    staticClass: "main-visual__head-text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300",
      "data-aos-duration": "800"
    }
  }, [_vm._v("Link-Net Korea")])])]), _c('div', {
    staticClass: "scrolldown scrolldown--double-arrow scrolldown--light"
  }, [_c('div', {
    staticClass: "scrolldown__arrow"
  }, [_c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down",
      "color": "white"
    }
  }), _c('icon-chevron', {
    attrs: {
      "size": "small",
      "direction": "down",
      "color": "white"
    }
  })], 1), _c('div', {
    staticClass: "scrolldown__txt"
  }, [_vm._v("Scroll Down")])])], 1), _c('section', {
    staticClass: "main-section main-section--solution"
  }, [_c('v-container', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "bg-deco bg-deco--blue",
    staticStyle: {
      "background-image": "url(/images/main/bg-deco-blue.png)"
    }
  }), _c('div', {
    staticClass: "bg-deco bg-deco--yellow",
    staticStyle: {
      "background-image": "url(/images/main/bg-deco-yellow.png)"
    }
  }), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl font-play line-height-11",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('span', {
    staticClass: "outline-text"
  }, [_vm._v("Our ")]), _vm._v("Solutions")])]), _c('v-row', {
    staticClass: "row--large"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/i-oneNet"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution01.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("i-oneNET")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("망연계 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("i-oneNET은 분리된 망 간 안전한 서비스 및 파일 연계 기능을 제공하는 솔루션입니다. 보안위협으로 부터 내부망의 보안성을 높이고, 동시에 업무 편의성까지 제공합니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/i-oneNAC"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution02.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("i-oneNAC")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("네트워크 접근통제 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("i-oneNAC은 내부 사용자 및 단말에 대한 인증을 강화하여 비인가 단말에 대한 네트워크 접근을 차단하고 정보자산을 보호하는 네트워크 접근 통제 솔루션입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/SafeIP"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution03.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("Safe IP")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("IP관리 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("Safe IP는 네트워크 사용 단말기의 증가함에 따라 IP주소 관리 및 관리 중앙화로 복잡하고 다양한 네트워크 를 관리하기 위한 IP관리 솔루션 입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/NGS"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution04.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("NGS")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("접근제어 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("NGS는 내부시스템에 접속하는 경로를 일원화하여 시스템 접근제어와 안전한 계정관리 및 패스워드관리를 한번에 제공하는 3 in 1 솔루션입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/i-oneJTac"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution05.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("i-oneJTac")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("재택근무 보안 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("i-oneJTac은 재택근무 보안 고려사항을 준수하며, 안전한 재택근무를 위한 편리한 원격접속과 강화된 보안까지 한번에 제공하는 재택근무 솔루션입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/security/mSABER"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution06.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("mSABER")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("차세대 통합인증 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("모바일 기기의 생체정보를 활용하여 다양한 업무시스템 로그인 시 PW 및 공인인증서를 대체하여 더욱 강력한 본인 인증서비스를 제공하는 차세대 인증 플랫폼입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/cloud"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution07.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("OKESTRO")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("클라우드 관리 플랫폼")]), _c('p', {
    staticClass: "page-text grey--text mt-12"
  }, [_vm._v("오케스트로에서 제공하고 있는 최적의 솔루션 Full Stack을 통해 클라우드 환경의 인프라에서 운영관리까지의 전 범위를 지원합니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "4",
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_c('router-link', {
    staticClass: "solution-card",
    attrs: {
      "to": "/solution/nozomi-networks"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 360 / 240,
      "src": "/images/solutions/solution08.jpg",
      "alt": "solution"
    }
  }), _c('div', {
    staticClass: "solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24"
  }, [_c('span', {
    staticClass: "font-play font-weight-bold primary--text"
  }, [_vm._v("Guardian")]), _c('h4', {
    staticClass: "tit tit--sm line-height-11 mt-8"
  }, [_vm._v("OT/IoT 사이버 보안 및 이상징후 탐지 솔루션")]), _c('p', {
    staticClass: "page-text grey--text mt-12",
    staticStyle: {
      "letter-spacing": "-0.05em !important"
    }
  }, [_vm._v("IT, OT 및 IoT 영역에서 혁신적인 인공지능 기술을 기반으로 산업제어 네트워크의 자산 현황 파악, 데이터 시각화 및 모니터링 등의 어려운 작업을 자동화하는 솔루션입니다.")]), _c('div', {
    staticClass: "d-flex justify-end mt-auto"
  }, [_c('span', {
    staticClass: "material-symbols-outlined font-size-28 mt-12"
  }, [_vm._v("arrow_right_alt")])])])], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "main-section main-section--partner"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit tit--xl font-play line-height-11 white--text",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('span', {
    staticClass: "outline-text outline-text--light"
  }, [_vm._v("Our ")]), _vm._v("Partners")])]), _c('div', {
    staticClass: "partner-section__content"
  }, [_c('div', {
    staticClass: "mb-40 mb-lg-80",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm tit--bar tit--bar-light mb-24"
  }, [_vm._v("보안솔루션")]), _c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$solution = _vm.solution) === null || _vm$solution === void 0 ? void 0 : _vm$solution.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1), _c('div', {
    staticClass: "mb-40 mb-lg-80",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm tit--bar tit--bar-light mb-24"
  }, [_vm._v("서버 및 주변기기")]), _c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$server = _vm.server) === null || _vm$server === void 0 ? void 0 : _vm$server.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1), _c('div', {
    staticClass: "mb-40 mb-lg-80",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "250"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm tit--bar tit--bar-light mb-24"
  }, [_vm._v("네트워크")]), _c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$network = _vm.network) === null || _vm$network === void 0 ? void 0 : _vm$network.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1), _c('div', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm tit--bar tit--bar-light mb-24"
  }, [_vm._v("상용소프트웨어")]), _c('v-row', {
    staticClass: "row-cols-lg-5"
  }, _vm._l((_vm$software = _vm.software) === null || _vm$software === void 0 ? void 0 : _vm$software.slice().reverse(), function (partner, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "sm": "4",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "border"
    }, [_c('div', {
      staticClass: "d-flex justify-center white"
    }, [_c('v-img', {
      attrs: {
        "max-width": "218",
        "aspect-ratio": 218 / 78,
        "src": `${partner.image}`,
        "alt": "logo"
      }
    })], 1)])]);
  }), 1)], 1)])])], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }