<template>
    <client-page class="app--main">
        <section class="main-visual">
            <v-container>
                <div class="main-visual__txt">
                    <div class="main-visual__txt-top" data-aos="fade-right" data-aos-duration="500">
                        <div class="main-visual__txt-area" data-aos="fade-right" data-aos-duration="500">
                            <div class="page-text font-size-lg-20 font-play mb-12">Link</div>
                            <h3>사람과 사람을 잇고</h3>
                        </div>
                        <div class="main-visual__txt-area" data-aos="fade-right" data-aos-duration="500">
                            <div class="page-text font-size-lg-20 font-play mb-12">Network</div>
                            <h3>사람과 세상을 연결하는</h3>
                        </div>
                        <div class="main-visual__txt-area" data-aos="fade-right" data-aos-duration="500">
                            <div class="page-text font-size-lg-20 font-play primary--text">Link & Network</div>
                        </div>
                    </div>
                    <h2 class="main-visual__head-text" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">Link-Net Korea</h2>
                </div>
            </v-container>

            <div class="scrolldown scrolldown--double-arrow scrolldown--light">
                <div class="scrolldown__arrow">
                    <icon-chevron size="small" direction="down" color="white" />
                    <icon-chevron size="small" direction="down" color="white" />
                </div>
                <div class="scrolldown__txt">Scroll Down</div>
            </div>
        </section>

        <section class="main-section main-section--solution">
            <v-container style="position: relative">
                <div class="bg-deco bg-deco--blue" style="background-image: url(/images/main/bg-deco-blue.png)"></div>
                <div class="bg-deco bg-deco--yellow" style="background-image: url(/images/main/bg-deco-yellow.png)"></div>

                <div class="tit-wrap text-center">
                    <h2 class="tit tit--xl font-play line-height-11" data-aos="fade-up"><span class="outline-text">Our </span>Solutions</h2>
                </div>

                <v-row class="row--large">
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/i-oneNet" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution01.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">i-oneNET</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">망연계 솔루션</h4>
                                <p class="page-text grey--text mt-12">i-oneNET은 분리된 망 간 안전한 서비스 및 파일 연계 기능을 제공하는 솔루션입니다. 보안위협으로 부터 내부망의 보안성을 높이고, 동시에 업무 편의성까지 제공합니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/i-oneNAC" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution02.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">i-oneNAC</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">네트워크 접근통제 솔루션</h4>
                                <p class="page-text grey--text mt-12">i-oneNAC은 내부 사용자 및 단말에 대한 인증을 강화하여 비인가 단말에 대한 네트워크 접근을 차단하고 정보자산을 보호하는 네트워크 접근 통제 솔루션입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/SafeIP" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution03.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">Safe IP</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">IP관리 솔루션</h4>
                                <p class="page-text grey--text mt-12">Safe IP는 네트워크 사용 단말기의 증가함에 따라 IP주소 관리 및 관리 중앙화로 복잡하고 다양한 네트워크 를 관리하기 위한 IP관리 솔루션 입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/NGS" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution04.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">NGS</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">접근제어 솔루션</h4>
                                <p class="page-text grey--text mt-12">NGS는 내부시스템에 접속하는 경로를 일원화하여 시스템 접근제어와 안전한 계정관리 및 패스워드관리를 한번에 제공하는 3 in 1 솔루션입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/i-oneJTac" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution05.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">i-oneJTac</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">재택근무 보안 솔루션</h4>
                                <p class="page-text grey--text mt-12">i-oneJTac은 재택근무 보안 고려사항을 준수하며, 안전한 재택근무를 위한 편리한 원격접속과 강화된 보안까지 한번에 제공하는 재택근무 솔루션입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/security/mSABER" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution06.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">mSABER</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">차세대 통합인증 솔루션</h4>
                                <p class="page-text grey--text mt-12">모바일 기기의 생체정보를 활용하여 다양한 업무시스템 로그인 시 PW 및 공인인증서를 대체하여 더욱 강력한 본인 인증서비스를 제공하는 차세대 인증 플랫폼입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/cloud" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution07.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">OKESTRO</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">클라우드 관리 플랫폼</h4>
                                <p class="page-text grey--text mt-12">오케스트로에서 제공하고 있는 최적의 솔루션 Full Stack을 통해 클라우드 환경의 인프라에서 운영관리까지의 전 범위를 지원합니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" data-aos="fade-up" data-aos-delay="100">
                        <router-link to="/solution/nozomi-networks" class="solution-card">
                            <v-img :aspect-ratio="360 / 240" src="/images/solutions/solution08.jpg" alt="solution" />
                            <div class="solution-card__content mt-16 mt-md-24 px-12 pb-16 pd-md-24">
                                <span class="font-play font-weight-bold primary--text">Guardian</span>
                                <h4 class="tit tit--sm line-height-11 mt-8">OT/IoT 사이버 보안 및 이상징후 탐지 솔루션</h4>
                                <p class="page-text grey--text mt-12" style="letter-spacing: -0.05em !important">IT, OT 및 IoT 영역에서 혁신적인 인공지능 기술을 기반으로 산업제어 네트워크의 자산 현황 파악, 데이터 시각화 및 모니터링 등의 어려운 작업을 자동화하는 솔루션입니다.</p>
                                <div class="d-flex justify-end mt-auto"><span class="material-symbols-outlined font-size-28 mt-12">arrow_right_alt</span></div>
                            </div>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="main-section main-section--partner">
            <v-container>
                <div class="tit-wrap text-center">
                    <h2 class="tit tit--xl font-play line-height-11 white--text" data-aos="fade-up" data-aos-delay="150"><span class="outline-text outline-text--light">Our </span>Partners</h2>
                </div>

                <div class="partner-section__content">
                    <div class="mb-40 mb-lg-80" data-aos="fade-up" data-aos-delay="150">
                        <h3 class="tit tit--sm tit--bar tit--bar-light mb-24">보안솔루션</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="(partner, idx) in solution?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                                <div class="border">
                                    <div class="d-flex justify-center white">
                                        <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mb-40 mb-lg-80" data-aos="fade-up" data-aos-delay="200">
                        <h3 class="tit tit--sm tit--bar tit--bar-light mb-24">서버 및 주변기기</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="(partner, idx) in server?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                                <div class="border">
                                    <div class="d-flex justify-center white">
                                        <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mb-40 mb-lg-80" data-aos="fade-up" data-aos-delay="250">
                        <h3 class="tit tit--sm tit--bar tit--bar-light mb-24">네트워크</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="(partner, idx) in network?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                                <div class="border">
                                    <div class="d-flex justify-center white">
                                        <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="300">
                        <h3 class="tit tit--sm tit--bar tit--bar-light mb-24">상용소프트웨어</h3>
                        <v-row class="row-cols-lg-5">
                            <v-col v-for="(partner, idx) in software?.slice().reverse()" :key="idx" cols="6" sm="4" md="3">
                                <div class="border">
                                    <div class="d-flex justify-center white">
                                        <v-img max-width="218" :aspect-ratio="218 / 78" :src="`${partner.image}`" alt="logo" />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-container>
        </section>

        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MainBanners from "@/components/client/main/main-banners.vue";
import MainSection from "@/components/client/main/main-section.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainTextBanner from "@/components/client/main/main-text-banner.vue";
import MainAuctionsBanner from "@/components/client/main/main-auctions-banner.vue";
// import BoardList from "@/components/client/board/board-list.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import IconChevron from "@/components/client/icons/icon-chevron.vue";
import { BOARD_YOUTUBE_CATEGORIES, BOARD_REPORT_CATEGORIES, BOARD_FORMS_CATEGORIES } from "@/assets/variables";

const categoriesYoutube = Object.values(BOARD_YOUTUBE_CATEGORIES);
const categoriesReport = Object.values(BOARD_REPORT_CATEGORIES);
const categoriesForms = Object.values(BOARD_FORMS_CATEGORIES);

export default {
    components: {
        ClientPage,
        MainBanners,
        MainSection,
        MainVisual,
        MainTextBanner,
        MainAuctionsBanner,
        // BoardList,
        PopupLayer,
        IconChevron,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data: () => ({
        categoriesYoutube,
        categoriesReport,
        categoriesForms,
        solution: [],
        server: [],
        network: [],
        software: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let solution = await api.v1.banners.gets({ params: { code: "partners", name: "보안솔루션" } });
            let server = await api.v1.banners.gets({ params: { code: "partners", name: "서버 및 주변기기" } });
            let network = await api.v1.banners.gets({ params: { code: "partners", name: "네트워크" } });
            let software = await api.v1.banners.gets({ params: { code: "partners", name: "상용소프트웨어" } });
            this.solution = solution.banner?.slides;
            this.server = server.banner?.slides;
            this.network = network.banner?.slides;
            this.software = software.banner?.slides;
            // console.log(server);
        },
    },
};
</script>

<style lang="scss" scoped>
.new-main {
    padding: 12px 0;
    display: flex;
    align-items: center;
    min-height: calc(100vh - var(--header-body));
}
.about-banner {
    width: 100%;
    position: relative;
    &__logo {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        max-width: 160px;
        .v-image {
            box-shadow: var(--shadow);
        }
    }
    &__inner {
        position: relative;
        width: 100vw;
        height: 160px;
        max-width: 100vw;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .about-banner {
        &__logo {
            max-width: 260px;
        }
        &__inner {
            height: 200px;
        }
    }
}
@media (min-width: 1024px) {
    .about-banner {
        &__logo {
            max-width: 382px;
        }
        &__inner {
            height: 350px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>