import axios from "@/plugins/axios";

let url = "/api/v1/me/introductions";

export default {
    post(index, locale, file){
        let formData = new FormData(); formData.append("introduction", file);
        let headers = { "Content-Types": "multipart/form-data" };
        return axios.post(`${url}/${index}/${locale}`, formData, { headers }).then(result => result.data);
    },
    delete(index, locale){
        return axios.delete(`${url}/${index}/${locale}`).then(result => result.data);
    }
}
