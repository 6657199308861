var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "visual"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.banner.slides.filter(function (slide) {
    return slide.image;
  }), function (slide, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('a', {
      staticClass: "visual__image",
      attrs: {
        "href": ""
      }
    }, [_c('picture', [_c('source', {
      attrs: {
        "srcset": slide.image,
        "media": "(max-width:768px)"
      }
    }), _c('img', {
      attrs: {
        "src": slide.image,
        "alt": ""
      }
    })])])]);
  }), 0)]), _c('div', {
    staticClass: "visual__prev"
  }), _c('div', {
    staticClass: "visual__next"
  }), _c('div', {
    staticClass: "visual__pagination visual__pagination--render"
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }