var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "default-banner main-auctions-banner"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.programs, function (program) {
    var _program$thumb, _program$at, _program$at$toDate;
    return _c('div', {
      key: program._id,
      staticClass: "swiper-slide"
    }, [_c('a', {
      staticClass: "main-auctions-banner__inner",
      attrs: {
        "href": "#"
      }
    }, [_c('div', {
      staticClass: "main-auctions-banner__img-wrap"
    }, [_c('div', {
      staticClass: "main-auctions-banner__img",
      style: `background-image:url(${program === null || program === void 0 ? void 0 : (_program$thumb = program.thumb) === null || _program$thumb === void 0 ? void 0 : _program$thumb.url});`
    })]), _c('div', {
      staticClass: "main-auctions-banner__con"
    }, [_c('h3', {
      staticClass: "main-auctions-banner__tit"
    }, [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.subject))]), _c('div', {
      staticClass: "main-auctions-banner__info"
    }, [_c('ul', [_c('li', [_c('strong', [_vm._v("장소")]), _c('span', [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : program.location))])]), _c('li', [_c('strong', [_vm._v("일시")]), _c('span', [_vm._v(_vm._s(program === null || program === void 0 ? void 0 : (_program$at = program.at) === null || _program$at === void 0 ? void 0 : (_program$at$toDate = _program$at.toDate) === null || _program$at$toDate === void 0 ? void 0 : _program$at$toDate.call(_program$at)))])])])])]), _c('v-divider', {
      staticClass: "grey-38"
    })], 1)]);
  }), 0), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-arrow-left mr-6"
  }), _c('span', [_vm._v("PREV")])]), _c('div', {
    staticClass: "swiper-pagination"
  }), _c('div', {
    staticClass: "swiper-next"
  }, [_c('span', [_vm._v("NEXT")]), _c('i', {
    staticClass: "icon icon-arrow-right ml-6"
  })])]);

}]

export { render, staticRenderFns }