<template>
    <router-link to="" @click.native="navigate" class="d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text">
        <template v-if="accessToken">
            <span>{{ $t("common.로그아웃") }}</span>
        </template>
        <template v-else>
            <i class="icon icon-person mr-4 mr-lg-8"></i>
            <span>{{ $t("common.로그인") }}</span>
        </template>
    </router-link>
</template>
<script>
export default {
    methods: {
        navigate(){
            if(this.accessToken){
                this.$store.dispatch("logout");
            }
            else{
                this.$router.push(`/login`);
            }
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    }
}
</script>