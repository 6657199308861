var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap align-center"
  }, [_c('h2', {
    staticClass: "tit tit--lg font-weight-regular"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._t("title-actions")], 2), _vm._t("default")], 2), _vm._t("immersive")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }