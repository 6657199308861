var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-top"
  }, [_c('v-container', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('ul', {
    staticClass: "footer-link"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/about/clients"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("회사소개")) + " ")])], 1), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=UEhBU0QwUmNLVTdUdVpDbDd0MUtKQT09",
      "target": "_blank"
    }
  }, [_vm._v("인재채용")])]), _c('li', [_c('router-link', {
    attrs: {
      "to": "/provision"
    }
  }, [_vm._v("이용약관")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/privacy"
    }
  }, [_vm._v("개인정보취급방침")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/email-collect"
    }
  }, [_vm._v("이메일무단수집거부")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/about/location"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("오시는 길")) + " ")])], 1)]), _c('ul', {
    staticClass: "footer-sns"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=linknkor",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/blog.png",
      "alt": "blog"
    }
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "https://www.facebook.com/linknkor",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/facebook.png",
      "alt": "facebook"
    }
  })])])])])], 1), _c('div', {
    staticClass: "footer-bottom"
  }, [_c('v-container', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', {}, [_c('h3', {
    staticClass: "font-play font-weight-bold primary--text mb-16"
  }, [_vm._v("Link-Net Korea")]), _c('ul', {
    staticClass: "footer-info mb-16"
  }, [_vm._l(_vm.info, function (item) {
    return _c('li', {
      key: item._id
    }, [_c('span', [_vm._v(_vm._s(item.term))]), _vm._v(" " + _vm._s(item.desc) + " ")]);
  }), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("대표이사")))]), _vm._v(_vm._s(_vm.$t("ceo")))]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("사업자등록번호")))]), _vm._v("891-86-00507")]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("문의번호")))]), _vm._v("02-780-2939")]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("팩스")))]), _vm._v("02-785-2939")]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("이메일")))]), _vm._v("sales@linknkor.co.kr")]), _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t("주소")))]), _vm._v(_vm._s(_vm.$t("address")))])], 2), _c('p', {
    staticClass: "copyright"
  }, [_vm._v("Copyright 2023. Link-Net Korea. All Rights Reserved.")])]), _c('div', [_c('u-scroll-top', {
    staticClass: "mt-md-auto ml-md-auto"
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }