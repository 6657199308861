
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../plugins/axios";
import api from "../api";

Vue.use(Vuex)

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
var scope = payload?.scope || [];


export default new Vuex.Store({
    state: {
        refreshToken,
        accessToken,
        payload,
        scope,
        
        _carts: JSON.parse(sessionStorage.getItem("_carts") || '[]'), // 장바구니 상품
        _pickups: JSON.parse(sessionStorage.getItem("_pickups") || '[]'), // 주문 픽업상품

        agreements: JSON.parse(sessionStorage.getItem("agreements") || '[]'),
    },
    mutations: {
        agreements(state, agreements){
            state.agreements = agreements;

            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken  }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;
            
            state.payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
            state.scope = state.payload?.scope || [];

            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem('accessToken', accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];

            axios.defaults.headers.common['Authorization'] = '';

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
        },

        addToCart(state, _carts){
            state._carts = _carts;
            sessionStorage.setItem("_carts", JSON.stringify(_carts));
        },

        checkout(state, _pickups){
            state._pickups = _pickups;
            sessionStorage.setItem("_pickups", JSON.stringify(_pickups));
        }
    },
    actions: {
        agreements({ commit }, _terms){
            commit("agreements", _terms);
        },

        async login({ commit }, { username, password }){
            var { refreshToken } = await api.auth.getRefreshToken({ username, password });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken } );
        },

        logout({ commit }) {
            commit("logout");
        },

        async refresh({ commit }, refreshToken){
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },

        addToCart({ commit }, _carts){
            commit("addToCart", _carts);
        },

        checkout({ commit }, _pickups){
            commit("checkout", _pickups);
        }
    },
    modules: {
    }
})
