<template>
    <!-- <div v-if="banner && ((pc && detect == 'pc') || (mobile && detect == 'mobile'))" class="main-visual swiper-container"> -->
    <div class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(slide, index) in banner.slides" :key="index" class="swiper-slide">
                    <!-- <div class="swiper-slide"> -->
                    <div class="main-visual__inner">
                        <div class="main-visual__img" :style="'background-image:url('+ slide?.image +');'"></div>
                        <!-- <component :is="dynamicComponent(slide.url)" :to="slide.url" :href="slide.url">
                            <picture>
                                <source :srcset="slide.image" media="(max-width:1200px)">
                                <img :src="slide.image" alt="">
                            </picture>
                        </component> -->
                        <div class="main-visual__con">
                            <h3 class="main-visual__tit">{{ slide?.meta?.title }}</h3>
                            <div class="main-visual__info">
                                <ul>
                                    <li>{{ slide?.meta?.location }}</li>
                                    <li>{{ slide?.meta?.date?.toDate() }}</li>
                                </ul>
                            </div>
                            <v-btn v-if="slide?.url" :to="slide?.isOuterLink ? undefined : slide?.url" :href="slide?.isOuterLink ? slide?.url : undefined" :target="slide?.isOuterLink ? '_blank': ''" small text color="white" class="main-visual__btn pa-4 ml-n4">VIEW MORE <i class="icon icon-bar white mt-2 ml-6"></i></v-btn>
                        </div>
                    </div>
                </div>
                <!-- <div class="swiper-slide">
                    <div class="main-visual__inner">
                        <div class="main-visual__img">
                            <picture>
                                <source srcset="/images/test-img.png" media="(min-width: 1200px)" />
                                <img src="/images/test-img.png" alt="이미지" />
                            </picture>
                        </div> -->
                <!-- <component :is="dynamicComponent(slide.url)" :to="slide.url" :href="slide.url">
                            <picture>
                                <source :srcset="slide.image" media="(max-width:1200px)">
                                <img :src="slide.image" alt="">
                            </picture>
                        </component> -->
                <!-- <div class="main-visual__con">
                            <h3 class="main-visual__tit">Modern &amp; Contemporary Art Auctions</h3>
                            <div class="main-visual__info">
                                <ul>
                                    <li>LONDON &amp; PARIS TEST TEST TEST TEST</li>
                                    <li>2022-00-00</li>
                                </ul>
                            </div>
                            <v-btn small text color="white" class="main-visual__btn pa-4 ml-n4" to="#">VIEW MORE <i class="icon icon-bar white mt-2 ml-6"></i></v-btn>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="swiper-control">
                <div class="swiper-prev"><i class="icon icon-arrow-left"></i></div>
                <div class="swiper-pagination"></div>
                <div class="swiper-next"><i class="icon icon-arrow-right"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import detect from "@/plugins/detect";
export default {
    props: {
        pc: Boolean,
        mobile: Boolean,
    },
    data() {
        return {
            detect,
            banner: null,
            visuals: [
                { link: "javascript:", pc: "/images/main/v1.jpg", mo: "/images/main/v1-mo.jpg" },
                { link: "javascript:", pc: "/images/main/v2.jpg", mo: "/images/main/v2-mo.jpg" },
                { link: "javascript:", pc: "/images/main/v3.jpg", mo: "/images/main/v3-mo.jpg" },
            ],
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            this.banner = banner;

            this.$nextTick(() => {
                this.swiper = new Swiper(".main-visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: ".main-visual .swiper-pagination",
                        type: "custom",
                        renderCustom: function(swiper, current, total) {
                            function numberAppend(n) {
                                return n < 10 ? "0" + n.toString() : n.toString();
                            }
                            return '<span class="swiper-pagination-current">' + numberAppend(current) + '</span> / <span class="swiper-pagination-total">' + numberAppend(total) + "</span>";
                        },
                    },
                    navigation: {
                        nextEl: ".main-visual .swiper-next",
                        prevEl: ".main-visual .swiper-prev",
                    },
                });
            });
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
}
.main-visual__inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.main-visual__img {
    width:100%;
    padding-top: calc(826/1700 * 100%);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.main-visual__con {
    color: #fff;
    background-color: var(--v-primary-base);
    padding: 30px 20px;
}
.main-visual__tit {
    font-size: 2.2rem;
    font-weight: 400;
    font-family: var(--font-notoserif);
    line-height: 1.2;
}
.main-visual__tit + .main-visual__info {
    margin-top: 14px;
}
.main-visual__info > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px -8px;
}
.main-visual__info > ul > li {
    position: relative;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    padding: 4px 8px;
}
.main-visual__info + .main-visual__btn {
    margin-top: 20px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__info > ul > li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, calc(-50% + 1px));
        -ms-transform: translate(0, calc(-50% + 1px));
        transform: translate(0, calc(-50% + 1px));
        display: block;
        width: 1px;
        height: 10px;
        background-color: #fff;
    }
    .main-visual__info > ul > li:first-child::before {
        display: none;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-visual{
        height: 826px;
    }
    .main-visual__img {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        padding-top:0;
    }
    .main-visual__con {
        position: absolute;
        bottom: 50px;
        right: 50px;
        width: 100%;
        max-width: 670px;
        padding: 60px;
    }
    .main-visual__tit {
        font-size: 4.6rem;
    }
    .main-visual__tit + .main-visual__info {
        margin-top: 26px;
    }
    .main-visual__info > ul {
        margin: -6px -12px;
    }
    .main-visual__info > ul > li {
        font-size: 2rem;
        padding: 6px 12px;
    }
    .main-visual__info + .main-visual__btn {
        margin-top: 56px;
    }
}
@media (min-width: 1380px) {
    .main-visual__con {
        right: 100px;
    }
}
</style>
