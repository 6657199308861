var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text",
    attrs: {
      "to": "/board/youtube"
    }
  }, [_c('i', {
    staticClass: "icon icon-youtube mr-4 mr-lg-8"
  }), _c('span', [_vm._v("캐터옥션 유튜브")])])], 1), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text",
    attrs: {
      "to": "/board/report"
    }
  }, [_c('i', {
    staticClass: "icon icon-report mr-4 mr-lg-8"
  }), _c('span', [_vm._v("캐터옥션 리포트")])])], 1), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text",
    attrs: {
      "to": "/board/forms"
    }
  }, [_c('i', {
    staticClass: "icon icon-forms mr-4 mr-lg-8"
  }), _c('span', [_vm._v("Forms & Archives")])])], 1), _vm.accessToken ? _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center font-size-12 font-size-md-14 grey-68--text",
    attrs: {
      "to": "/mypage/myinfo"
    }
  }, [_c('i', {
    staticClass: "icon icon-person mr-4 mr-lg-8"
  }), _c('span', [_vm._v("마이페이지")])])], 1) : _vm._e(), _c('v-col', {
    staticClass: "line-height-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }