'use strict';

module.exports = {

    // 공용
    common: {
        "SBA 국제유통센터": "SBA 全球贸易中心",
        "국제유통센터": "全球贸易中心",
        "로그인": "登录",
        "로그아웃": "登出",
        "글쓰기": "写作",
        "마이페이지": "Mypage",
        "페이지소개": "页面介绍",
        "매칭상담회": "商贸配对会",
        "자주 묻는 질문": "FAQ",
        "공지사항": "公告事项",
        "기업정보": "企业信息",
        "상품관리": "商品管理",
        "1:1문의": "1:1咨询",
        "개인정보처리방침": "个人信息处理方针",
        "셀러 회원 마이페이지": "卖家 会员 我的主页",
        "바이어 회원 마이페이지": "Buyer Mypage",

        "자세히 보기": "查看细节",
        "관리자 문의": "咨询管理者",
        "아이디 저장": "保存ID",
        "아이디 찾기": "找身份证",
        "비밀번호 찾기": "找密码",

        "번호": "No",
        "제목": "Title",
        "작성일": "Date",
        "조회수": "Hit",

        "매칭상담회 설명": "配对咨询会说明",
        "참여제품": "参与产品",
        "참여기업": "参与企业",
        "참여바이어": "参与客户",

        "전체": "全部",
        "신청하기": "申请",
        "진행중": "进行中",
        "참여중": "参与中",
        "종료": "结束",
        "신청": "应用",

        "select_all": ":: 全部 ::",
        "선정년도": "选定年度",
        "상품명": "商品名",
        "뒤로가기": "回去k",
        "바로가기": "立即进入",
        "상세내용": "详细内容",
        "SMC_홍보마당": "SMC宣传内容",
        "홈페이지": "网站",
        "전성분": "全成分",
        "컬러": "颜色",
        "사이즈": "尺寸",
        "제조국": "生产国家",
        "출시일자": "上市日期",
        "제조방법": "制造方法",
        "어워드_선정일": "首尔大奖选定日",
        "판매사이트": "销售网站",
        "sales_price": "销售价(消费者价)",
        "주요특징": "主要特点",
        "prodcut_essential_information": "商品必要内容",
        "매칭신청": "申请配对",
        "기업명": "公司名",
        "제품군": "产品群",
        "인증구분": "验证区分",
        "서울어워드": "首尔奖",
        "슈퍼루키": "超级菜鸟",
        "기타": "等等",
        "업체명": "公司名",
        "대표자이름": "代表姓名",
        "카테고리": "分类",
        "주소": "地址",
        "회사소개서": "公司简介",
        "활동지역": "活动区",
        "바이어유형": "买家类型",
        "온라인": "在线",
        "오프라인": "实体店",
        "저장": "保存",
        "시간": "时间",
        "저장되었습니다": "已成功保存",
        "취소": "取消",
        "매칭거절": "匹配拒绝",

        "시도": "City/State",
        "시구군": "City/gu/gun",

        attachments: "附件",
        list: "列表",
        title: "标题",
        confirm: "确认",
        writing: "写作",
        answer_complete: "回答完毕",
        unanswered: "未答复",
        number: "数字",
        inquiry: "询问",
        answer_status: "回答状态",
        date_created: "创建日期",
        search: "搜索",
        required_fields: "必填字段",

        셀러기업모집중: "招募卖家公司",
        바이어신청중: "买家申请",
        companyClick: '※ 点击公司名称可查看详细信息。'
    },

    // 마이페이지
    mypage: {
        "회원정보수정": "会员信息",
        support_center: "1:1咨询",
        my_products: "商品管理",
        my_events: "配对洽谈会",
        company_information: "企业信息",
        company_profile: "公司简介",
        company_profile_upload: ".pdf, .xlsx, .jpg, .png 可附加50Mb以下（推荐PDF附件）",
        company_profile_warning: "※在公司介绍的情况下，公司可以阅读。 上传前请务必删除个人信息。",
        CEO_name: "代表人姓名",
        company_edit_message1: "如果您需要编辑您的公司信息，请务必在编辑后点击下方的",
        save: "保存",
        company_edit_message2: "按钮。",
        buyer_activity_area: "客户活动地区",
    },

    // 메인페이지
    main: {
        platform: {
            tit: "平台使用方法",
            subtit: "Seller菜单",
            txt: "Seller's manual",
            subtit2: "Buyer菜单",
            txt2: "Buyer's manual",
        }
    },

    // 페이지소개
    about: {
        title: "商贸配对会平台",
        intro_txt: "支持获得Seoul Awards的商品和在SBA全球贸易中心发掘的客户之间的配对。",
        intro_txt2: "这是为了寻找可出口海外的优秀商品的流通商与需要开拓可流通及出口优秀商品的销路的中小企业，而有流通渠道入驻的B2B商贸配对会平台。",
        qna_tit: "如何确认账号信息呢？",
        qna_txt: "通过申请Seoul Awards时登记的邮件地址，向中小制造企业发送了账户信息。 请确认邮件，登录后在‘我的主页’修改信息。",
        qna_tit2: "我想成为商贸配对会的卖家！",
        qna_txt2: "BSB商贸配对会只有参加了在SBA全球贸易中心进行的‘Seoul Awards’、‘Super Rookie’的企业才能参加。",
        qna_btn2: "直接进入Seoul Awards 申请页面",
        qna_tit3: "我想成为商贸配对会的买家！",
        qna_txt3: "如果您想作为买家参与，请通过下方的链接地址申请买家注册，审核后将另行通知。",
        qna_btn3: "直接进入买家申请页面",
    },

    // 자주묻는질문
    faq: {
        type: {
            이용문의: "使用咨询",
            홈페이지문의: "网站咨询",
            개인정보문의: "个人信息咨询",
            기타: "其他",
        }
    },
    schedule_dialog: {
        title: '请设置可参与配对洽谈会的时间。',
        sub_title: '日后需要更改时，请在[我的主页-配对咨询会-配对日程]中修改。',
        buyer: {
            'ex1': "匹配咨询可以在线或离线进行。",
            'ex2': "请选择您喜欢的匹配咨询方式。",
            'ex3': '※ 线上进行时，会使用Zoom程序进行配对辅导，线下时，会与企业进行一对一的面对面配对辅导'
        },
        seller: {
            ex1: "下面的日程表是客户可以配对的日程。",
            ex2: "最多选择三项企业可进行的日程后申请配对。",
            ex3: "客户最后确定时间后即可完成配对。",
            ex4: "※ 不希望配对时，请点击右侧的拒绝配对。",
        },
        application_question: "申请配对咨询吗？",
        application_complete: "已完成配对咨询。",
        reject_question: "拒绝配对咨询吗？",
        reject_compelete: "已拒绝配对咨询。",
    }
};