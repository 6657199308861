var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.banner.slides, function (slide, index) {
    var _slide$meta, _slide$meta2, _slide$meta3, _slide$meta3$date;
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "main-visual__inner"
    }, [_c('div', {
      staticClass: "main-visual__img",
      style: 'background-image:url(' + (slide === null || slide === void 0 ? void 0 : slide.image) + ');'
    }), _c('div', {
      staticClass: "main-visual__con"
    }, [_c('h3', {
      staticClass: "main-visual__tit"
    }, [_vm._v(_vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta = slide.meta) === null || _slide$meta === void 0 ? void 0 : _slide$meta.title))]), _c('div', {
      staticClass: "main-visual__info"
    }, [_c('ul', [_c('li', [_vm._v(_vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta2 = slide.meta) === null || _slide$meta2 === void 0 ? void 0 : _slide$meta2.location))]), _c('li', [_vm._v(_vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta3 = slide.meta) === null || _slide$meta3 === void 0 ? void 0 : (_slide$meta3$date = _slide$meta3.date) === null || _slide$meta3$date === void 0 ? void 0 : _slide$meta3$date.toDate()))])])]), slide !== null && slide !== void 0 && slide.url ? _c('v-btn', {
      staticClass: "main-visual__btn pa-4 ml-n4",
      attrs: {
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? undefined : slide === null || slide === void 0 ? void 0 : slide.url,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? slide === null || slide === void 0 ? void 0 : slide.url : undefined,
        "target": slide !== null && slide !== void 0 && slide.isOuterLink ? '_blank' : '',
        "small": "",
        "text": "",
        "color": "white"
      }
    }, [_vm._v("VIEW MORE "), _c('i', {
      staticClass: "icon icon-bar white mt-2 ml-6"
    })]) : _vm._e()], 1)])]);
  }), 0), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev"
  }, [_c('i', {
    staticClass: "icon icon-arrow-left"
  })]), _c('div', {
    staticClass: "swiper-pagination"
  }), _c('div', {
    staticClass: "swiper-next"
  }, [_c('i', {
    staticClass: "icon icon-arrow-right"
  })])]);

}]

export { render, staticRenderFns }