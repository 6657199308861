<template>
    <div class="main-text-banner">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <router-link to="/form/consignment-sale" class="main-text-banner__txt">캐터옥션에서 작품 위탁을 받습니다</router-link>
                </div>
                <div class="swiper-slide">
                    <router-link to="/form/consignment-sale" class="main-text-banner__txt">캐터옥션에서 작품 위탁을 받습니다</router-link>
                </div>
                <div class="swiper-slide">
                    <router-link to="/form/consignment-sale" class="main-text-banner__txt">캐터옥션에서 작품 위탁을 받습니다</router-link>
                </div>
                <div class="swiper-slide">
                    <router-link to="/form/consignment-sale" class="main-text-banner__txt">캐터옥션에서 작품 위탁을 받습니다</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
export default {
    props: {
    },
    data(){
        return{
            swiper: undefined,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            this.$nextTick(() => {
                this.swiper = new Swiper ('.main-text-banner .swiper-container', {
                    centeredSlides: true,
                    loop: true,
                    autoplay: {
                        delay: 1,
                        disableOnInteraction: false,
                    },
                    speed: 20000,
                    breakpoints: {
                        1200: {
                            freeMode: true,
                        },
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// main-text-banner swiper
.main-text-banner .swiper-wrapper{
    align-items: center;
    -webkit-transition-timing-function:linear!important;
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important;
}
.main-text-banner .swiper-slide{
    text-align: center;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-text-banner{
        overflow: hidden;
        padding: 0 12%;
    }
    .main-text-banner .swiper-container{
        overflow: visible;
    }
}

// main-text-banner
.main-text-banner{
    border-top:1px solid var(--v-primary-base);
    border-bottom: 1px solid var(--v-primary-base);;
}
.main-text-banner__txt{
    display: inline-block;
    font-size: 1.4rem;
    color:var(--v-primary-base);
    text-align: center;
    padding: 16px 10px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .main-text-banner__txt{
        font-size: 1.8rem;
        padding: 26px 19px;
    }
}
@media (min-width:1200px){
}
</style>