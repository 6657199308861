var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "scroll-top",
    attrs: {
      "fab": "",
      "tile": "",
      "outlined": "",
      "color": "white",
      "transition": "slide-x-transition"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/common/arrowUp.png",
      "alt": ""
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }