import Vue from "vue";
import VueI18n from "vue-i18n";
import ko from "@/locales/ko.js";
import en from "@/locales/en.js";

Vue.use(VueI18n);

// Load all locales and remember context
function loadMessages() {
    const context = require.context("../locales", true, /[a-z0-9-_]+\.js$/i);

    const messages = context
        .keys()
        .map((key) => ({
            key,
            locale: key.match(/[a-z0-9-_]+/i)[0]
        }))
        .reduce(
            (messages, {
                key,
                locale
            }) => ({
                ...messages,
                [locale]: context(key),
            }), {}
        );

    return {
        context,
        messages
    };
}

const {
    context,
    messages
} = loadMessages();

// VueI18n instance
export const i18n = new VueI18n({
    locale: "ko",
    // fallbackLocale: "ko",
    allowComposition: true,
    messages: { ko, en },
});

// Hot updates
if (module.hot) {
    module.hot.accept(context.id, () => {
        const {
            messages: newMessages
        } = loadMessages();

        Object.keys(newMessages)
            .filter((locale) => messages[locale] !== newMessages[locale])
            .forEach((locale) => {
                messages[locale] = newMessages[locale];
                i18n.setLocaleMessage(locale, messages[locale]);
            });
    });
}