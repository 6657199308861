import axios from "@/plugins/axios";

let url = "/api/v1/me/coupons";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
}
