var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-text-banner"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('router-link', {
    staticClass: "main-text-banner__txt",
    attrs: {
      "to": "/form/consignment-sale"
    }
  }, [_vm._v("캐터옥션에서 작품 위탁을 받습니다")])], 1), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('router-link', {
    staticClass: "main-text-banner__txt",
    attrs: {
      "to": "/form/consignment-sale"
    }
  }, [_vm._v("캐터옥션에서 작품 위탁을 받습니다")])], 1), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('router-link', {
    staticClass: "main-text-banner__txt",
    attrs: {
      "to": "/form/consignment-sale"
    }
  }, [_vm._v("캐터옥션에서 작품 위탁을 받습니다")])], 1), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('router-link', {
    staticClass: "main-text-banner__txt",
    attrs: {
      "to": "/form/consignment-sale"
    }
  }, [_vm._v("캐터옥션에서 작품 위탁을 받습니다")])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }