<template>
    <v-btn
        fab
        tile
        outlined
        color="white"
        transition="slide-x-transition"
        class="scroll-top"
        @click="$vuetify.goTo(target, options)"
    >
        <!-- <i class="icon icon-arrow-up icon-arrow-up-white"></i>
        <span class="font-size-12 font-size-md-16 mt-4">TOP</span> -->
        <img src="/images/common/arrowUp.png" alt="" />
    </v-btn>
</template>

<script>
export default {
    data() {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",

            show: false,
        };
    },
    mounted() {},
    methods: {},
    computed: {
        target: function() {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>
