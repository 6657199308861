import axios from "@/plugins/axios";

let url = "/api/console/auction/files";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post({ _program, _work, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_program) formData.append("_program", _program);
        if (_work) formData.append("_work", _work);
        if (index !== undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
