<template>
<div v-if="banner" class="visual">
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div v-for="(slide, index) in banner.slides.filter(slide => slide.image)" :key="index" class="swiper-slide">
                <a class="visual__image" href="">
                    <picture>
                        <!-- 모바일 배너 -->
                        <source :srcset="slide.image" media="(max-width:768px)">
                        <!-- pc 배너 -->
                        <img :src="slide.image" alt="">
                    </picture>
                </a>
            </div>
        </div>
    </div>
    <div class="visual__prev"></div>
    <div class="visual__next"></div>
    <div class="visual__pagination visual__pagination--render"></div>
</div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";

export default {
    props: {
        code: { type: String, default: null }
    },
    data() {
        return {
            filter: {
                code: this.$props.code
            },

            banner: null,

            // 더미 슬라이드
            lists: new Array(3),
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try{
                var { banner } = await api.v1.banners.get({
                    code: this.$props.code
                });

                this.banner = banner;
                this.$nextTick(() => {
                    this.render();
                })
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        render() {
            this.swiper = new Swiper('.visual .swiper-container', {
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: '.visual__pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        // 두 자리수 채우기
                        function pad(d) {
                            return (d < 10) ? '0' + d.toString() : d.toString();
                        }
                        return '<span class="' + className + '"><em>' + pad(index + 1) + '</em></span>';
                    }
                },
                navigation: {
                    nextEl: '.visual__next',
                    prevEl: '.visual__prev'
                },
            });
        }
    },
}
</script>
