import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // 국문
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // 회사소개 / 회사소개
        path: "/about",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/AboutPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 연혁 
        path: "/about/clients",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/ClientPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 연혁 
        path: "/about/history",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/HistoryPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 오시는길
        path: "/about/location",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/LocationPage.vue"),
        props: true,
    },
    {
        // 서비스
        path: "/service",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/ServicePage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneNet
        path: "/solution/security/i-oneNet",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/IoneNetPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneNAC
        path: "/solution/security/i-oneNAC",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/IoneNACPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / SafeIP
        path: "/solution/security/SafeIP",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/SafeIPPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / NGS
        path: "/solution/security/NGS",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/NGSPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneJTac
        path: "/solution/security/i-oneJTac",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/IoneJTacPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / mSABER
        path: "/solution/security/mSABER",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/MSABERPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 인프라
        path: "/solution/infra",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/InfraPage.vue"),
        props: true,
    },
    {
        // 솔루션 / OT보안
        path: "/solution/nozomi-networks",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/NozomiNetworksPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 클라우드
        path: "/solution/cloud",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/solution/CloudPage.vue"),
        props: true,
    },
    {
        // 컨텍트
        path: "/contact",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/contact/ContactPage.vue"),
        props: true,
    },
    {
        // 개인정보취급방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/info/PrivacyPage.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/provision",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/info/ProvisionPage.vue"),
        props: true,
    },
    {
        // 이메일무단수집거부
        path: "/email-collect",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/info/EmailCollectPage.vue"),
        props: true,
    },

    // 영문
    {
        // Main 페이지
        path: "/en",
        component: () => import(/* webpackChunkName: "main" */ "../pages/client/en/Main.vue"),
    },
    {
        // 회사소개 / 회사소개
        path: "/en/about",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/about/AboutPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 연혁 
        path: "/en/about/clients",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/about/ClientPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 연혁 
        path: "/en/about/history",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/about/HistoryPage.vue"),
        props: true,
    },
    {
        // 회사소개 / 오시는길
        path: "/en/about/location",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/about/LocationPage.vue"),
        props: true,
    },
    {
        // 서비스
        path: "/en/service",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/service/ServicePage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneNet
        path: "/en/solution/security/i-oneNet",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/IoneNetPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneNAC
        path: "/en/solution/security/i-oneNAC",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/IoneNACPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / SafeIP
        path: "/en/solution/security/SafeIP",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/SafeIPPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / NGS
        path: "/en/solution/security/NGS",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/NGSPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / i-oneJTac
        path: "/en/solution/security/i-oneJTac",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/IoneJTacPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 보안솔루션 / mSABER
        path: "/en/solution/security/mSABER",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/MSABERPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 인프라
        path: "/en/solution/infra",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/InfraPage.vue"),
        props: true,
    },
    {
        // 솔루션 / OT보안
        path: "/en/solution/nozomi-networks",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/NozomiNetworksPage.vue"),
        props: true,
    },
    {
        // 솔루션 / 클라우드
        path: "/en/solution/cloud",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/solution/CloudPage.vue"),
        props: true,
    },
    {
        // 컨텍트
        path: "/en/contact",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/contact/ContactPage.vue"),
        props: true,
    },
    {
        // 개인정보취급방침
        path: "/en/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/info/PrivacyPage.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/en/provision",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/info/ProvisionPage.vue"),
        props: true,
    },
    {
        // 이메일무단수집거부
        path: "/en/email-collect",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/en/sub/info/EmailCollectPage.vue"),
        props: true,
    },

    // {
    //     // 가이드
    //     path: "/guide",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/request",
    //     component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/response",
    //     component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
    //     props: true,
    // },
    
    // {
    //     // 로그인
    //     path: "/login",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
    //     props: true,
    // },
    // {
    //     // 비밀번호 찾기
    //     path: "/login/find-password",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
    //     props: true,
    // },
    // {
    //     // 아이디 찾기
    //     path: "/login/find-id",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입
    //     path: "/join",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/type",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/form",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 완료
    //     path: "/join/complete",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 응찰/낙찰 내역
    //     path: "/mypage/bids-:type/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/BidsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 상세
    //     path: "/mypage/consignment-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청내역 목록
    //     path: "/mypage/consignment-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 상세
    //     path: "/mypage/private-list/:_form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 작품문의 목록
    //     path: "/mypage/private-list",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PrivateListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청
    //     path: "/mypage/consignment-form",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ConsignmentFormPage.vue"),
    //     props: true,
    // },
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    // {
    //     // 회원 정보 상세
    //     path: "/mypage/myinfo",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 정보 변경
    //     path: "/mypage/myinfo/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Search 페이지
    //     path: "/auction-search/:keyword",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/SearchPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지
    //     path: "/auction-halls/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Hall 페이지 - Group
    //     path: "/auction-halls/:hall/groups/:group",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionHallPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction Work 페이지
    //     path: "/auction-works/:_work",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionWorkPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Auction - 지난경매
    //     path: "/auction-results/:hall",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/auction/AuctionResultsPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sales
    //     path: "/private-sales/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/private-sales/PrivateSalesPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 위탁안내
    //     path: "/how-to/consignment",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToConsignmentPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/bid",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - 응찰안내
    //     path: "/how-to/:tab",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/how-to/HowToBidPage.vue"),
    //     props: true,
    // },
    // {
    //     // How To Bid - FAQ
    //     path: "/center/faqs",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Collection
    //     path: "/collection",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/CollectionPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Museum Services
    //     path: "/museum-services",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/MuseumServicesPage.vue"),
    //     props: true,
    // },
    // {
    //     // Service - Valuations
    //     path: "/valuations",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/service/ValuationsPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - About & Mission
    //     path: "/about-mission",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/AboutMissionPage.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 공지사항
    //     path: "/center/notice/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // About - 언론보도
    //     path: "/board/press",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Press.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // About - 위치안내
    //     path: "/location",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/LocationPage.vue"),
    //     props: true,
    // },
    // {
    //     // 캐터옥션 유튜브
    //     path: "/board/youtube",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Youtube.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 캐터옥션 리포트
    //     path: "/board/report",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Report.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // Forms & Archives
    //     path: "/board/forms",
    //     component: () => import(/* webpackChunkName: "board" */ "../pages/client/board/Forms.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 경매약관
    //     path: "/auction-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/AuctionPolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 서비스이용약관
    //     path: "/service-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/ServicePolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 개인정보처리방침
    //     path: "/privacy-policy",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
    //     props: true,
    // },
    // {
    //     // 문의 및 제휴
    //     path: "/form/inquiry-partner",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
    //     props: true,
    // },
    // {
    //     // 위탁신청문의
    //     path: "/form/consignment-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/ConsignmentSale.vue"),
    //     props: true,
    // },
    // {
    //     // Private Sale 문의
    //     path: "/form/private-sale",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/PrivateSale.vue"),
    //     props: true,
    // },
    // {
    //     // 개인정보처리방침
    //     path: "/about/press",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/board/AuthorBoard.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 고객사
        path: "/console/client",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/client/ClientsList.vue"),
        props: true,
    },
    {
        // 관리자 - 고객사
        path: "/console/client/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/client/ClientsView.vue"),
        props: true,
    },
    {
        // 관리자 - 고객사
        path: "/console/client/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/client/ClientsView.vue"),
        props: true,
    },
    {
        // 관리자 - 파트너스
        path: "/console/partners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/partners/PartnersList.vue"),
        props: true,
    },
    {
        // 관리자 - 파트너스
        path: "/console/partners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/partners/PartnersView.vue"),
        props: true,
    },
    {
        // 관리자 - 파트너스
        path: "/console/partners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/partners/PartnersView.vue"),
        props: true,
    },
    {
        // 관리자 - 인프라
        path: "/console/infra",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/infra/InfraList.vue"),
        props: true,
    },
    {
        // 관리자 - 인프라
        path: "/console/infra/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/infra/InfraView.vue"),
        props: true,
    },
    {
        // 관리자 - 인프라
        path: "/console/infra/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/infra/InfraView.vue"),
        props: true,
    },
    {
        // 관리자 - 국문 연혁
        path: "/console/history",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/history/HistoryView.vue"),
        props: true,
    },
    {
        // 관리자 - 영문 연혁
        path: "/console/historyen",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/history/HistoryEnView.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
