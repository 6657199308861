<template>
    <v-select v-model="$i18n.locale" :items="items" item-text="text" outlined dense item-value="value" hide-details class="locale-select" @input="input" />
</template>
<script>
import axios from "@/plugins/axios";

export default {
    data() {
        return {
            items: [
                { text: "KO", value: "ko" },
                { text: "EN", value: "en" },
            ],
        };
    },
    methods: {
        input(locale) {
            axios.defaults.headers.common["Accept-Language"] = locale;
        },
    },
};
</script>

<style lang="scss" scoped>
.locale-select {
    max-width: 90px;
    border-radius: 999px !important;
    margin-left: auto;
}
@media (min-width:1200px){
    .locale-select {
        margin-left: 0;
    }
}
</style>