<template>
    <section class="main-section">
        <v-container>
            <div class="tit-wrap align-center">
                <h2 class="tit tit--lg font-weight-regular">{{ title }}</h2>
                <slot name="title-actions" />
            </div>
            <slot />
        </v-container>
        <slot name="immersive" />
    </section>
</template>

<script>
export default {
    props: {
        title: String
    },
    data(){
        return{
        }
    },
};
</script>
<style scoped>
</style>