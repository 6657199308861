import axios from "@/plugins/axios";

let url = "/api/console/statistics";

export default {
    getMonthlySales(data){
        return axios.get(`${url}/monthlySales`, data).then(result => result.data);
    },

    getDailySales(data){
        return axios.get(`${url}/dailySales`, data).then(result => result.data);
    },

    getDayOfWeekSales(data){
        return axios.get(`${url}/dayOfWeekSales`, data).then(result => result.data);
    },

    getProductsSales(data){
        return axios.get(`${url}/productsSales`, data).then(result => result.data);
    },
}
