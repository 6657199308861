import axios from "@/plugins/axios";

let url = "/api/v1/me/purchases";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    }
}
